import React from 'react';
import InnerBox from './InnerBox';
import { Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from '@common/constants';
import Dashboard from '../features/dashboard/Dashboard';
import { ChatsList } from '@features/chat-list/ChatList';
import Login from '../features/auth/Login';

const Layout = () => {
  return (
    <>
      <InnerBox>
        <Routes>
          <Route path={'/'} element={<Login />} />
          <Route path={APP_ROUTES.SIGN_UP} element={<Login />} />
          {/*<Route path={APP_ROUTES.DASHBOARD} element={user ? <Dashboard /> : <NoPremiss/>} />*/}
          <Route path={APP_ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={APP_ROUTES.CHATS_LIST} element={<ChatsList />} />
        </Routes>
      </InnerBox>
    </>
  );
};

export default Layout;
