import React from 'react';
import { Card, CardBody, HStack, IconButton, Link, Text } from '@chakra-ui/react';
import moment from 'moment/moment';
import { Client } from '@features/dashboard/models';
import { getClientBotName, getClientFullname } from '@features/common/utils';
import { ChatIcon } from '@chakra-ui/icons';
import { SYSTEM_USER_ID } from '@features/common/constants';

interface ClientCardProps {
  client: Client;
  onClick: (client: Client) => void;
  showAgentChats: (id: number) => () => void;
}

const ClientCard = (props: ClientCardProps) => {
  const { client, onClick, showAgentChats } = props;
  const { id, messages } = client;

  const bot = getClientBotName(client);

  const { author, sentAt, text } = messages[0];
  const timestamp = moment(sentAt).format('HH:mm DD.MM');

  const managerMessage = messages.find((m) => m.author.id !== id && m.author.id !== SYSTEM_USER_ID);

  return (
    <Card>
      <CardBody>
        <HStack>
          <Text fontSize="md" as="b">
            {getClientFullname(client)}
          </Text>
          <Text fontSize="sm" as="b" color="grey">
            {id}
          </Text>
          <IconButton size="xs" aria-label="Open chat history" icon={<ChatIcon />} onClick={() => onClick(client)} />
        </HStack>
        {managerMessage ? (
          <HStack>
            <Text fontSize="sm" as="b" color="grey">{`${bot} с `}</Text>{' '}
            <Link fontSize="sm" as="b" color="grey" onClick={showAgentChats(managerMessage.author.id)}>
              {getClientFullname(managerMessage.author)}
            </Link>
          </HStack>
        ) : (
          <Text fontSize="sm" as="b" color="grey">
            {bot}
          </Text>
        )}
        <HStack>
          <Text fontSize="sm">{getClientFullname(author)}</Text>
          <Text fontSize="sm" color="grey">{`* ${timestamp}`}</Text>
        </HStack>
        <Text fontSize="sm">{text}</Text>
      </CardBody>
    </Card>
  );
};

export default ClientCard;
