import { Client, User } from '@features/dashboard/models';

export const getClientFullname = ({ name, lastName }: User) => `${name}${lastName ? ` ${lastName}` : ''}`;

export const getClientBotName = ({ telegramId, vkId, waId }: Client) => {
  let bot = '';
  if (telegramId) {
    bot = 'Tg';
  }
  if (waId) {
    bot = 'WA';
  }
  if (vkId) {
    bot = 'ВК';
  }

  return bot;
};
