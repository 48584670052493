// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/features/auth/login.index.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;ECCE,aAAA;EACA,uBAAA;EACA,mBAAA;EDDA,cAAA;AACF","sourcesContent":["@import '../../styles/variables.scss';\n\n.auth-page {\n  @include flex-center;\n  margin: 0 auto;\n}","$min-width: 360px;\n\n@mixin flex-center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
