import React, { useEffect, useRef, useState } from 'react';
import './chat.index.scss';
import { Box, Flex } from '@chakra-ui/react';
import { Chat } from '@features/chat-list/components/Chat';
import { useGetChatMessagesFullMutation } from '@api/apiClient';
import { APP_ROUTES, LOCAL_STORAGE } from '@common/constants';
import { FullChat, FullChatsResponse } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@app/hooks';
import { maxChatsPageConst, timeToRefresh } from '@features/chat-list/chat.namespace';

export const ChatsListOnlyMy = (props: {
  needRefresh: boolean;
  setterNeedRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  listLoaded: boolean;
  setListLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  searchString: string;
}) => {
  const navigate = useNavigate();
  const [getAgentChats, { data, isLoading, error }] = useGetChatMessagesFullMutation();

  const eventScrollAndGetNewChats = () => {
    try {
      const endElem = document.querySelector('.end-of-document')!;
      const position = endElem.getBoundingClientRect();
      if (position.bottom < window.innerHeight && !loading.current) {
        setPage((prev) => prev + 1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleApiError = () => {
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS);
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT);
    navigate(APP_ROUTES.SIGN_UP);
  };

  const user = useAuth();

  const loading = useRef<boolean>(true);

  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(10 ** 10);

  const [agentChats, setAgentChats] = useState<FullChatsResponse | null>(null);
  const [agentChatsToShow, setAgentChatsToShow] = useState<FullChatsResponse | null>(null);
  const [activeAgentChat, setActiveAgentChat] = useState<number>(0);

  useEffect(() => {
    loading.current = true;
    props.setListLoaded(false);

    if (props.needRefresh && !agentChats && user) {
      getAgentChats({ managerId: user!.user!.id, page, onlyMyChats: true, searchString: props.searchString })
        .unwrap()
        .then((res) => {
          setAgentChats(res);
          setActiveAgentChat(res.userCurrentChats.manager_bot_chat_id);
          if (res.chatsData.length === 0) {
            setMaxPage(page);
          }
        })
        .catch(() => {
          handleApiError();
        });
      loading.current = false;
      props.setListLoaded(true);
      props.setterNeedRefresh(false);
    }
  }, [props.needRefresh]);

  useEffect(() => {
    loading.current = true;
    props.setListLoaded(false);

    if (!agentChats && user) {
      getAgentChats({ managerId: user!.user!.id, page, onlyMyChats: true, searchString: props.searchString })
        .unwrap()
        .then((res) => {
          setAgentChats(res);
          setActiveAgentChat(res.userCurrentChats.manager_bot_chat_id);
          if (res.chatsData.length === 0) {
            setMaxPage(page);
          }
        })
        .catch(() => {
          handleApiError();
        });
      loading.current = false;
      props.setListLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (page > 0 && page < maxPage && !loading.current && user) {
      loading.current = true;

      getAgentChats({ managerId: user!.user!.id, page, onlyMyChats: true, searchString: props.searchString })
        .unwrap()
        .then((res) => {
          if (res.chatsData.length > 0) {
            if (agentChats && agentChats.chatsData && agentChats.chatsData.length > 0) {
              const newChats = JSON.parse(JSON.stringify(agentChats));
              const resNewChats = res.chatsData.filter((chat: FullChat) => {
                if (chat !== null) {
                  if (chat.chatId !== activeAgentChat) {
                    return chat;
                  }
                }
              });

              newChats.chatsData = newChats.chatsData.concat(resNewChats);
              setAgentChats(newChats);
            } else {
              setAgentChats(res);
            }
          } else {
            setMaxPage(page);
            const btn = document.getElementsByClassName('chat__btn_next-page')[0];
            btn.classList.add('hidden');
          }
          loading.current = false;
        })
        .catch(() => {
          handleApiError();
        });
    }
  }, [page]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (window.scrollY > 70) {
        return;
      }

      let newChats: FullChatsResponse = await getAgentChats({
        managerId: user!.user!.id,
        page: 0,
        onlyMyChats: true,
        searchString: props.searchString,
      }).unwrap();

      /*for (let curPage = 1; curPage <= page; curPage++) {
        const pageChats = await getAgentChats({
          managerId: user!.user!.id,
          page: curPage,
          onlyMyChats: true,
        }).unwrap();

        if (pageChats && pageChats.chatsData && pageChats.chatsData.length > 0) {
          newChats = {
            ...newChats,
            chatsData: newChats.chatsData.concat(pageChats.chatsData),
          };
        } else {
          break;
        }
      }*/

      if (newChats && newChats.chatsData && newChats.chatsData.length > 0) {
        let counter = 0;
        let newChatsData = newChats.chatsData.filter((chat) => {
          if (chat !== null) {
            if (chat.chatId === activeAgentChat) {
              if (counter === 0) {
                return chat;
              }
              counter++;
            } else {
              return chat;
            }
          }
        });

        setAgentChats({ ...newChats, chatsData: newChatsData });
        setPage(0);
        setMaxPage(maxChatsPageConst);
        window.scroll(0, 0);
      }
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, [page]);

  useEffect(() => {
    window.addEventListener('scroll', eventScrollAndGetNewChats);

    if (maxPage !== maxChatsPageConst) {
      window.removeEventListener('scroll', eventScrollAndGetNewChats);
    }

    return () => {
      window.removeEventListener('scroll', eventScrollAndGetNewChats);
    };
  }, [maxPage]);

  useEffect(() => {
    if (agentChats && agentChats.chatsData && agentChats.chatsData.length > 1) {
      let newChats = JSON.parse(JSON.stringify(agentChats));

      newChats.chatsData = newChats.chatsData.filter((chat: FullChat) => {
        if (chat !== null) {
          if (chat.chat_is_client || chat.project_status) {
            return chat;
          }
        }
      });

      setAgentChatsToShow(newChats);
    }
  }, [agentChats]);

  return (
    <Box>
      <Flex flexDirection="column" className={'chat-list'}>
        {agentChatsToShow && agentChatsToShow.chatsData && agentChatsToShow.chatsData.length > 0 ? (
          agentChatsToShow.chatsData.map((chat, index) => {
            if (chat !== null) {
              return (
                <div key={'chat-id-' + index}>
                  <Chat
                    managerId={user!.user!.id}
                    chatId={chat.chatId}
                    clientName={`${chat.client_name} ${chat.client_last_name ? `${chat.client_last_name[0]}.` : ''}`}
                    hasunread={chat.hasunread}
                    messageText={chat.message_text}
                    projectStatus={chat.project_status}
                    chatIsClient={chat.chat_is_client}
                    isMultyChat={chat.is_multy_chat}
                    messageAuthorId={chat.message_author_id}
                    clientPhotoLink={chat.user_photos_link}
                    agentCurrentChatId={activeAgentChat ?? 0}
                    setActiveAgentChat={setActiveAgentChat}
                    clientId={chat.chat_client_id}
                    chatLastMessageAt={chat.chat_last_message_at}
                    messageunCount={chat.messageun_count}
                    agentChats={agentChats}
                    filterOnlyMyChats={true}
                    setAgentChats={setAgentChats}
                    setPage={setPage}
                    setMaxPage={setMaxPage}
                    agentsList={chat.agents_list}
                    queuedMessages={chat.queued_messages}
                    daysFromProject={chat.project_start_days_number}
                    lastMessageName={chat.message_author_name}
                  />
                </div>
              );
            }
          })
        ) : !loading.current ? (
          <b>Нет чатов</b>
        ) : null}

        {loading.current ? <b>Загрузка...</b> : null}

        <button
          className={['chat__btn_next-page', maxPage === page ? 'hidden' : ''].join(' ')}
          onClick={() => {
            setPage((prev) => prev + 1);
          }}
        >
          еще чаты
        </button>
      </Flex>

      <div className={'end-of-document'}></div>
    </Box>
  );
};
