import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Button, Center, Flex, Input, InputGroup, useToast, VStack } from '@chakra-ui/react';
import { useLoginMutation, useLoginTgMutation } from '@api/apiClient';
import { LoginRequest, UserResponse } from '@api/types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice';
import { APP_ROUTES, LOCAL_STORAGE } from '@common/constants';
import './login.index.scss';
import { TelegramWebApps } from 'telegram-webapps-types-new';

// @ts-ignore
let tgWebApp: TelegramWebApps.WebApp | null = window.Telegram.WebApp;

const Login = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  const handleLoginTgError = () => {
    console.log('error while logging in via tg');
  };

  const redirect = (user: UserResponse) => {
    const isUsersRoleAdmin =
      user!.user!.role.filter((role) => {
        return role.name === 'admin';
      }).length > 0;

    let tgWebAppData = null;
    if (tgWebApp && tgWebApp.initData) {
      tgWebAppData = tgWebApp.initData;
    }

    navigate(isUsersRoleAdmin && tgWebAppData !== null ? APP_ROUTES.CHATS_LIST : APP_ROUTES.DASHBOARD);
    // navigate(APP_ROUTES.CHATS_LIST); // for test
  };

  const [formState, setFormState] = useState<LoginRequest>({
    email: '',
    password: '',
  });

  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const [login, { isLoading }] = useLoginMutation();
  const [loginTg] = useLoginTgMutation();

  async function handleTelegramId(telegramId: number) {
    if (tgWebApp) {
      await loginTg({ telegramId: telegramId })
        .unwrap()
        .then((res) => {
          dispatch(setCredentials(res));
          redirect(res);
        })
        .catch(() => {
          handleLoginTgError();
          tgWebApp = null;
        });
    }
  }

  const [tryingToAuthTg, setTryingToAuthTg] = useState<boolean>(true);

  useEffect(() => {
    const userInfo: UserResponse = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_CREDENTIALS)!);

    const userInfoTimeout: Date = new Date(JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT)!));

    if (userInfoTimeout < new Date()) {
      localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS);
      localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT);
    }

    if (userInfo?.user?.name && userInfo?.access_token && userInfoTimeout) {
      dispatch(setCredentials(userInfo));
      redirect(userInfo);
    }

    // login via telegramId
    if (tgWebApp) {
      if (tgWebApp.initDataUnsafe) {
        const webAppDataUnsafe = tgWebApp.initDataUnsafe;

        if (webAppDataUnsafe && webAppDataUnsafe.user && webAppDataUnsafe.user.id) {
          console.log('logging in via tg id');
          const telegramId = webAppDataUnsafe.user.id;
          handleTelegramId(telegramId);
        }
      }
    }

    setTryingToAuthTg(false);
  }, []);

  return (
    <Flex className={'auth-page'}>
      <Center h="500px">
        <VStack spacing="4">
          <Box>Для входа в кабинет введите свой емейл и пароль:</Box>
          <InputGroup size="md">
            <Input name="email" type="text" placeholder="Емейл" value={formState.email} onChange={handleChange} />
          </InputGroup>
          <Input
            name="password"
            type="password"
            placeholder="Пароль"
            value={formState.password}
            onChange={handleChange}
          />
          <Button
            colorScheme="blue"
            onClick={async () => {
              try {
                const userInfo = await login(formState).unwrap();
                dispatch(setCredentials(userInfo));
                redirect(userInfo);
              } catch (err) {
                toast({
                  status: 'error',
                  title: 'Error',
                  description: 'Oh no, there was an error!',
                  isClosable: true,
                });
              }
            }}
            isLoading={isLoading}
          >
            Войти
          </Button>

          {tryingToAuthTg ? <div>Попытка входа через ТГ</div> : null}
        </VStack>
      </Center>
    </Flex>
  );
};

export default Login;
