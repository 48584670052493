// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 360px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  margin: 2px;
}

.hidden {
  display: none;
}

@media (max-width: 360px) {
  * {
    font-size: 14px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/index.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,8JAAA;EAGA,mCAAA;EACA,kCAAA;EAEA,gBCTU;ADKZ;;AAOA;EACE,+EAAA;AAJF;;AAQA;EACE,WAAA;AALF;;AAQA;EACE,aAAA;AALF;;AAQA;EACE;IACE,0BAAA;EALF;AACF","sourcesContent":["@import './variables.scss';\n\nbody {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n\n  min-width: $min-width;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n#root {\n  margin: 2px;\n}\n\n.hidden {\n  display: none;\n}\n\n@media (max-width: $min-width) {\n  * {\n    font-size: 14px !important;\n  }\n}","$min-width: 360px;\n\n@mixin flex-center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
