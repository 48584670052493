import './chat.index.scss';
import { Box, Flex, list } from '@chakra-ui/react';
import { WarnChat } from './components/WarnChat';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@app/hooks';
import { useEnterChatMutation, useGetWarnClientsMutation } from '@api/apiClient';
import { WarnClientsResponse } from '@api/types';
import { useEffect, useRef, useState } from 'react';
import { timeToRefresh } from './chat.namespace';

// @ts-ignore
const tgWebApp: TelegramWebApps.WebApp = window.Telegram.WebApp;

export const WarnChatList = (props: {
  needRefresh: boolean;
  setterNeedRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  listLoaded: boolean;
  setListLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const user = useAuth();
  const [getWarnClients, { data, isLoading, error }] = useGetWarnClientsMutation();
  const [warnClients, setWarnClients] = useState<WarnClientsResponse | null>(null);
  const [enterChat] = useEnterChatMutation();

  const loading = useRef(true);

  const enterTgChat = async (chatId: number, newClientId: number = 0) => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isIframe = window.self !== window.top;
    
    await enterChat({
      chatId: chatId,
      managerId: user!.user!.id,
      newClientId: newClientId
    }).then(async () => {
          props.setterNeedRefresh(true);
        }
      )
      .catch(() => {
        handleApiError();
      });
    if (isTouchDevice || isIframe) {
      tgWebApp.close();
    }
  };


  useEffect(() => {
    if(props.listLoaded === false){
      return;
    }
    loading.current = true;

    if (!warnClients && user ) {
      getWarnClients({ managerId: user!.user!.id })
        .unwrap()
        .then((res) => {
          setWarnClients(res);
        })
        .catch(() => {
          handleApiError();
        });
      loading.current = false;
    }
  }, [props.listLoaded]);

  useEffect(() => {
    loading.current = true;

    if (props.needRefresh && user ) {
      getWarnClients({ managerId: user!.user!.id })
        .unwrap()
        .then((res) => {
          setWarnClients(res);
        })
        .catch(() => {
          handleApiError();
        });
      loading.current = false;
    }
  }, [props.needRefresh]);

  useEffect(() => {
    const interval = setInterval(async () => {
      let newClients: WarnClientsResponse = await getWarnClients({
        managerId: user!.user!.id,
      }).unwrap();

      if (newClients && newClients.clientsData && newClients.clientsData.length > 0) {
        setWarnClients(newClients);
      }
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, [warnClients]);

  return (
    <Flex className={'warn-chats'}>
        {
          warnClients?.clientsData.map(client => {
            return (
            <WarnChat key={'warnchat-id-' + client.chatId} enterTgChat={enterTgChat} clientId={client.clientId}
               chatId={client.chatId} clientName={client.clientName}
                hoursCount={client.hoursCount} warnLevel={client.warnLevel}
                isAnswerWait={client.isAnswerWait} isPayWait={client.isPayWait} isNewClient={client.isNewClient}></WarnChat>)
          })
        }
    </Flex>
  );
};
function handleApiError() {
  throw new Error('Function not implemented.');
}

