import React, { useState } from 'react';
import { Chat } from '@features/dashboard/models';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { getClientFullname } from '@features/common/utils';
import moment from 'moment/moment';
import { useGetChatMessagesQuery } from '@api/apiClient';
import { skipToken } from '@reduxjs/toolkit/query';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import MessageCard from '@features/dashboard/components/MessageCard';

interface ManagerChatsProps {
  chats: Chat[];
  isOpen: boolean;
  onClose: () => void;
}

const ManagerChats = (props: ManagerChatsProps) => {
  const { chats, isOpen, onClose } = props;
  const [chatId, setChatId] = useState<number | null>(null);
  const { data: messages, isFetching } = useGetChatMessagesQuery(chatId || skipToken);

  return (
    <Modal onClose={onClose} size="4xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Manager's chats`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {chats.map((chat) => {
            const createdAt = moment(chat.createdAt).format('HH:mm DD.MM');

            const lastMessage = chat.messages?.[0];

            return (
              <Box p={2} key={chat.id} borderWidth="1px" borderRadius="m">
                <Box mb={2}>
                  <HStack>
                    <Text fontSize="md" as="b">
                      {getClientFullname(chat.client)}
                    </Text>
                    <Text fontSize="sm" as="b" color="grey">
                      {chat.client.id}
                    </Text>
                    <Text fontSize="m" color="grey">{`(created at ${createdAt})`}</Text>
                  </HStack>
                  {lastMessage && (
                    <Box>
                      <HStack>
                        <Text fontSize="sm">{getClientFullname(lastMessage.author)}</Text>
                        <Text fontSize="sm" color="grey">{`* ${moment(lastMessage.sentAt).format(
                          'HH:mm DD.MM',
                        )}`}</Text>
                      </HStack>
                      <Text fontSize="sm">{lastMessage.text}</Text>
                    </Box>
                  )}
                  <Center>
                    {chat.id === chatId ? (
                      <IconButton
                        size="sm"
                        aria-label="Close"
                        icon={<ChevronUpIcon />}
                        onClick={() => {
                          setChatId(null);
                        }}
                      />
                    ) : (
                      <IconButton
                        size="sm"
                        aria-label="Open"
                        icon={<ChevronDownIcon />}
                        onClick={() => {
                          setChatId(chat.id);
                        }}
                      />
                    )}
                  </Center>
                </Box>
                {chat.id === chatId && !isFetching && messages && (
                  <>
                    <Divider />
                    <Box h={300} overflow="auto">
                      {messages.map((message, i) => (
                        <MessageCard key={`chat-${chat.id}-message-${i}`} message={message} />
                      ))}
                    </Box>
                  </>
                )}
                {chat.id === chatId && isFetching && (
                  <Center>
                    <Spinner />
                  </Center>
                )}
              </Box>
            );
          })}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ManagerChats;
