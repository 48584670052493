import React, { useState } from 'react';
import { TelegramWebApps } from 'telegram-webapps-types-new';
import {
  useAddClientToFavoriteMutation,
  useEnterChatMutation,
  useGetChatMessagesFullMutation,
  useCancelMessageMutation,
  useAddChatToMultyMutation,
} from '@api/apiClient';
import { APP_ROUTES, LOCAL_STORAGE } from '@common/constants';
import { useNavigate } from 'react-router-dom';
import { FullChatsResponse, QueuedMessage } from '@api/types';
import { getHumanDate, maxChatsPageConst } from '@features/chat-list/chat.namespace';
import CancelMessageBtn from '@features/chat-list/components/CancelMessageBtn';

// @ts-ignore
const tgWebApp: TelegramWebApps.WebApp = window.Telegram.WebApp;

export const Chat = (props: {
  managerId: number;
  chatId: number;
  clientName: string;
  hasunread: boolean;
  messageText: string;
  projectStatus: boolean;
  chatIsClient: boolean;
  isMultyChat: boolean;
  messageAuthorId: number;
  clientPhotoLink: string;
  agentCurrentChatId: number;
  clientId: number;
  chatLastMessageAt: string;
  messageunCount: number;
  filterOnlyMyChats: boolean;
  agentChats: FullChatsResponse | null;
  daysFromProject: number;
  lastMessageName: string;
  agentsList: string;
  setAgentChats: React.Dispatch<React.SetStateAction<FullChatsResponse | null>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setActiveAgentChat: React.Dispatch<React.SetStateAction<number>>;
  setMaxPage: React.Dispatch<React.SetStateAction<number>>;
  queuedMessages?: QueuedMessage[];
}) => {
  const navigate = useNavigate();

  const handleApiError = () => {
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS);
    localStorage.removeItem(LOCAL_STORAGE.USER_CREDENTIALS_TIMEOUT);
    navigate(APP_ROUTES.SIGN_UP);
  };

  const [enterChat] = useEnterChatMutation();
  const [cancelMessage] = useCancelMessageMutation();

  const enterTgChat = async (chatId: number) => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const isIframe = window.self !== window.top;

    await enterChat({
      chatId: chatId,
      managerId: props.managerId,
      newClientId: 0,
    })
      .then(async () => {
        props.setActiveAgentChat(chatId);
      })
      .catch(() => {
        handleApiError();
      });

    if (isTouchDevice || isIframe) {
      tgWebApp.close();
    }
  };

  const [userBtnsActionsVisible, setUserBtnsActionsVisible] = useState<boolean>(false);
  const handleUserActionsBtnClick = () => {
    setUserBtnsActionsVisible((prev) => !prev);
  };
  const [addUserToFavorite] = useAddClientToFavoriteMutation();
  const [addChatToMulty] = useAddChatToMultyMutation();

  const handleAddUserToFavorites = (managerId: number, clientId: number, chatId: number) => {
    addUserToFavorite({
      managerId: managerId,
      clientId: clientId,
    }).then(() => {
      let newChats: FullChatsResponse = props.agentChats!;

      let newChatsData = newChats!.chatsData.map((chat) => {
        if (chat.chatId === chatId) {
          return {
            ...chat,
            chat_is_client: !chat.chat_is_client,
          };
        } else {
          return chat;
        }
      });

      newChats = { ...newChats, chatsData: newChatsData };
      props.setAgentChats(newChats);
    });
  };

  const handleAddChatToMulty = (chatId: number) => {
    addChatToMulty({
      chatId: chatId,
    }).then(() => {
      let newChats: FullChatsResponse = props.agentChats!;

      let newChatsData = newChats!.chatsData.map((chat) => {
        if (chat.chatId === chatId) {
          return {
            ...chat,
            is_multy_chat: !chat.is_multy_chat,
          };
        } else {
          return chat;
        }
      });

      newChats = { ...newChats, chatsData: newChatsData };
      props.setAgentChats(newChats);
    });
  };

  const queuedMessages = props.queuedMessages
    ?.map((message) => ({ ...message }))
    .sort((a, b) => b.timestamp - a.timestamp);

  return (
    <div className={['chat', props.chatId === props.agentCurrentChatId ? 'chat_active' : ''].join(' ')}>
      <article>
        <div className={'chat__user-description'}>
          <div
            className={'chat__user-desc-text'}
            onClick={async () => {
              await enterTgChat(props.chatId);
            }}
          >
            <span>
              <h4>{props.clientName}</h4>
              {queuedMessages && (
                <CancelMessageBtn
                  message={queuedMessages[0]}
                  cancel={async () => {
                    await cancelMessage({ id: queuedMessages[0].id });
                  }}
                />
              )}
            </span>

            {props.messageunCount ? (
              <span className={'chat__msg-counter'}>
                <p>{props.messageunCount}</p>
              </span>
            ) : null}
          </div>

          <div className={'chat__user-buttons'}>
            {props.projectStatus ? (
              <div className={'chat__msg-prefix'}>
                <p className={'chat__msg-prefix_days'}>{Math.floor(props.daysFromProject) + 'д'}</p>
              </div>
            ) : null}

            {props.projectStatus ? (
              <div className={'chat__msg-prefix'}>
                <div className={'credit-card'} />
              </div>
            ) : null}

            <div className={['chat__msg-prefix'].join(' ')}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddChatToMulty(props.chatId);
                }}
              >
                <div className={['people-multy', props.isMultyChat ? 'multy-chat' : ''].join(' ')} />
              </button>
            </div>

            <div className={['chat__msg-prefix'].join(' ')}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddUserToFavorites(props.managerId, props.clientId, props.chatId);
                }}
              >
                <div className={['handshake', props.chatIsClient ? 'favorite-client' : ''].join(' ')} />
              </button>
            </div>
          </div>
        </div>

        {props.isMultyChat && <p className={'chat__user-sub-desc-text'}>{'+' + props.agentsList}</p>}

        {queuedMessages && <p className="chat_msg__text">{`Вы: ${queuedMessages[0].text}`}</p>}

        {!queuedMessages && (
          <p
            className={'chat_msg__text'}
            onClick={async (e) => {
              await enterTgChat(props.chatId);
            }}
          >
            {props.messageAuthorId === props.managerId
              ? 'Вы: '
              : props.lastMessageName
              ? props.lastMessageName + ': '
              : ''}
            {props.messageText && props.messageText.length > 0 ? props.messageText : '...'}
          </p>
        )}

        <p
          className={'chat_msg__date'}
          onClick={async () => {
            await enterTgChat(props.chatId);
          }}
        >
          {getHumanDate(props.chatLastMessageAt)}
        </p>
      </article>
    </div>
  );
};
