import React from 'react';
import { Client, Message } from '@features/dashboard/models';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { getClientFullname } from '@features/common/utils';
import MessageCard from '../../../features/dashboard/components/MessageCard';

interface ClientHistoryProps {
  client: Client;
  history: Message[];
  isOpen: boolean;
  onClose: () => void;
  showAgentChats: (id: number) => () => void;
}

const ClientHistory = (props: ClientHistoryProps) => {
  const { client, history, isOpen, onClose, showAgentChats } = props;

  const sortedHistory = history
    .map((m) => ({ ...m, sentAt: new Date(m.sentAt) }))
    .sort((a, b) => a.sentAt.getTime() - b.sentAt.getTime());

  return (
    <Modal onClose={onClose} size="4xl" isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Chat history for ${getClientFullname(client)}`}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {sortedHistory.map((message) => (
            <MessageCard message={message} onClick={showAgentChats(message.author.id)} />
          ))}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ClientHistory;
