import React from 'react';
import { Message } from '../../../features/dashboard/models';
import moment from 'moment/moment';
import { Box, HStack, Link, Text } from '@chakra-ui/react';
import { getClientFullname } from '../../../features/common/utils';

interface MessageCardProps {
  message: Message;
  onClick?: () => void;
}

const MessageCard = ({ message, onClick }: MessageCardProps) => {
  const { author, text, sentAt } = message;
  const timestamp = moment(sentAt).format('HH:mm DD.MM');
  const lines = text.split('\n');

  return (
    <Box p={2} key={`${author.id}-${timestamp}`}>
      <HStack>
        {onClick ? (
          <Link fontSize="sm" as="b" onClick={onClick}>
            {getClientFullname(author)}
          </Link>
        ) : (
          <Text fontSize="sm" as="b">
            {getClientFullname(author)}
          </Text>
        )}
        <Text fontSize="sm" color="grey">{`${timestamp}`}</Text>
      </HStack>
      {lines.map((line, i) => (
        <Text key={i} fontSize="sm">
          {line}
        </Text>
      ))}
    </Box>
  );
};

export default MessageCard;
