import React, { useEffect, useMemo, useState } from 'react';
import { Box, Center, Flex, IconButton, Input, Select, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import { useGetClientsQuery, useGetManagerChatsQuery } from '../../api/apiClient';
import ClientCard from '../../features/dashboard/components/ClientCard';
import moment from 'moment';
import { ChevronLeftIcon, ChevronRightIcon, RepeatIcon, SmallCloseIcon } from '@chakra-ui/icons';
import ClientHistory from '../../features/dashboard/components/ClientHistory';
import { Client, User } from '@features/dashboard/models';
import { skipToken } from '@reduxjs/toolkit/query';
import ManagerChats from '../../features/dashboard/components/ManagerChats';
import { SYSTEM_USER_ID } from '@features/common/constants';
import { getClientBotName, getClientFullname } from '@features/common/utils';
import { useAuth } from '@app/hooks';
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '@common/constants';

const Dashboard = () => {
  const navigate = useNavigate();

  const user = useAuth();
  useEffect(() => {
    if (user) {
      const isUsersRoleAdmin =
        user!.user!.role.filter((role) => {
          return role.name === 'admin';
        }).length > 0;

      if (!isUsersRoleAdmin) {
        navigate(APP_ROUTES.CHATS_LIST);
      }
      // navigate(APP_ROUTES.CHATS_LIST);
    }
  }, []);

  const [startOfData, setStartOfData] = useState(moment().startOf('day'));
  const endOfData = startOfData.clone().add(1, 'days');
  const {
    data: clients,
    isFetching,
    refetch,
  } = useGetClientsQuery({
    start: startOfData.toISOString(),
    end: endOfData.toISOString(),
  });

  const goToPrevDay = () => {
    setStartOfData(startOfData.clone().add(-1, 'days'));
  };

  const goToNextDay = () => {
    setStartOfData(startOfData.clone().add(1, 'days'));
  };

  const managers: User[] =
    clients?.reduce((acc, client) => {
      const chatters = client.messages
        .filter((m) => m.author.id !== client.id && m.author.id !== SYSTEM_USER_ID)
        .map((m) => m.author);
      chatters.forEach((author) => {
        if (!acc.some((u) => u.id === author.id)) {
          acc.push(author);
        }
      });

      return acc;
    }, [] as User[]) || [];
  const [managerFilter, setManagerFilter] = useState<number | null>(null);

  const bots = clients?.map(getClientBotName).filter((v, i, self) => self.indexOf(v) === i) || [];
  const [botFilter, setBotFilter] = useState<string | null>(null);

  const [clientFilter, setClientFilter] = useState('');

  const dialogs = useMemo(() => {
    if (!clients) {
      return [];
    }

    if (!managerFilter && !botFilter && !clientFilter) {
      return clients;
    }

    let result = clients;

    if (managerFilter) {
      result = result.filter((c) => c.messages.some((m) => m.author.id === managerFilter));
    }

    if (botFilter) {
      result = result.filter((c) => getClientBotName(c) === botFilter);
    }

    if (clientFilter) {
      result = result.filter((c) => getClientFullname(c).toLowerCase().includes(clientFilter.toLowerCase()));
    }

    return result;
  }, [clients, managerFilter, botFilter, clientFilter]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [current, setCurrent] = useState<Client | null>(null);

  const [agent, setAgent] = useState<number | null>(null);
  const { data: agentChats } = useGetManagerChatsQuery(agent || skipToken);

  const handleHistoryOpen = (client: Client) => {
    setCurrent(client);
    onOpen();
  };

  const showAgentChats = (id: number) => () => {
    onClose();
    setAgent(id);
  };

  const resetFilters = () => {
    setClientFilter('');
    setBotFilter(null);
    setManagerFilter(null);
  };

  return (
    <Box>
      <Flex mb={3} gap={6} alignContent="center" wrap="wrap">
        <Flex>
          <IconButton aria-label="Предыдущий день" icon={<ChevronLeftIcon />} onClick={goToPrevDay} />
          <Box p={2}>
            <Text>{startOfData.format('DD.MM.YYYY')}</Text>
          </Box>
          <IconButton
            aria-label="Следующий день"
            icon={<ChevronRightIcon />}
            onClick={goToNextDay}
            isDisabled={endOfData.isAfter(moment.now())}
          />
          <IconButton aria-label={'Обновить'} icon={<RepeatIcon />} onClick={() => refetch()} ml={2} />
        </Flex>
        <Flex gap={2} wrap="wrap">
          <Flex>
            <Select
              value={managerFilter || ''}
              placeholder="Select manager"
              onChange={(e) => {
                setManagerFilter(Number(e.target.value));
              }}
            >
              {managers.map((m) => (
                <option value={m.id} key={m.id}>
                  {m.name}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex>
            <Select
              value={botFilter || ''}
              placeholder="Select bot"
              onChange={(e) => {
                setBotFilter(e.target.value);
              }}
            >
              {bots.map((bot) => (
                <option value={bot} key={bot}>
                  {bot}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex>
            <Input
              value={clientFilter}
              placeholder="Search by client's name"
              onChange={(e) => setClientFilter(e.target.value)}
            />
          </Flex>
          <Flex>
            <IconButton
              aria-label="Reset filters"
              icon={<SmallCloseIcon />}
              onClick={resetFilters}
              isDisabled={!botFilter && !clientFilter && !managerFilter}
            />
          </Flex>
        </Flex>
      </Flex>
      {isFetching && (
        <Center>
          <Spinner />
        </Center>
      )}
      {!isFetching && dialogs.length === 0 && <Text>Нет данных за этот день</Text>}
      {!isFetching &&
        dialogs.length > 0 &&
        dialogs.map((dialog) => (
          <ClientCard key={dialog.id} client={dialog} onClick={handleHistoryOpen} showAgentChats={showAgentChats} />
        ))}
      {current && (
        <ClientHistory
          client={current}
          history={current.messages}
          isOpen={isOpen}
          onClose={onClose}
          showAgentChats={showAgentChats}
        />
      )}
      {agentChats && (
        <ManagerChats
          chats={agentChats}
          isOpen={!!agent}
          onClose={() => {
            setAgent(null);
          }}
        />
      )}
    </Box>
  );
};

export default Dashboard;
