import React from 'react';
import Layout from './layout/Layout';
import { Box } from '@chakra-ui/react';
import { useAuth } from './app/hooks';
import Login from './features/auth/Login';

function App() {
  const auth = useAuth();

  return (
    <Box>
      {
        auth.user
          ? <Layout/>
          : <Login/>
      }
    </Box>
  )
}

export default App;
