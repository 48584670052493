import React, { useRef, useState } from 'react';
import './chat.index.scss';
import { ChatHeader } from '@features/chat-list/components/ChatHeader';
import { ChatsListOnlyMy } from '@features/chat-list/ChatListOnlyMy';
import { ChatsListAll } from '@features/chat-list/ChatListAll';
import { WarnChatList } from './WarnChatList';

export const ChatsList = () => {
  const [filterMyChats, setFilterMyChats] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(false);
  const [listLoaded, setListLoaded] = useState(false);
  const [searchString, setSearchString] = useState('');
  const search = useRef('');

  return (
    <div>
      <ChatHeader
        setterMyFilter={setFilterMyChats}
        filterMyChats={filterMyChats}
        setSearchString={setSearchString}
        search={search}
        setterNeedRefresh={setNeedRefresh}
      />
      <WarnChatList
        setterNeedRefresh={setNeedRefresh}
        needRefresh={needRefresh}
        listLoaded={listLoaded}
        setListLoaded={setListLoaded}
      ></WarnChatList>
      {filterMyChats ? (
        <ChatsListOnlyMy
          setterNeedRefresh={setNeedRefresh}
          needRefresh={needRefresh}
          listLoaded={listLoaded}
          setListLoaded={setListLoaded}
          searchString={searchString}
        />
      ) : (
        <ChatsListAll
          setterNeedRefresh={setNeedRefresh}
          needRefresh={needRefresh}
          listLoaded={listLoaded}
          setListLoaded={setListLoaded}
          searchString={searchString}
          search={search}
        />
      )}
    </div>
  );
};
